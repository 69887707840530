<template>
部门管理
</template>

<script>
export default {
name: "Department"
}
</script>

<style scoped>

</style>
