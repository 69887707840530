<template>
岗位管理
</template>

<script>
export default {
name: "Post"
}
</script>

<style scoped>

</style>
